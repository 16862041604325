import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Error, Warning } from '@material-ui/icons';

import { AlertTooltip } from 'components/AlertTooltip';
import {
  audienceExpiredText,
  audienceSoonWillBeExpiredText,
  isExpired,
  isSoonToBeExpired,
  segmentDaysRemaining,
  segmentExpiredText,
  segmentSoonWillBeExpired,
} from 'utils/expiryDates';

type Segments = {
  expiryDate: string | null | undefined;
  segmentStatus: string | null | undefined;
}[];

interface Props {
  name?: string;
  status?: string;
  estimatedExpiryDate: number | null;
  segments?: Segments;
  fromDeal?: boolean;
}

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '5px',
  },
});

export const AudienceCell = memo(
  ({ name, status, estimatedExpiryDate, segments = [], fromDeal = false }: Props) => {
    const { flex, marginRight } = useStyles();

    const showExpiredAudeinceAlert = isExpired(status);
    const showAudienceExpireSoonAlert = isSoonToBeExpired(estimatedExpiryDate, status);

    const showExpiredSegmentAlert = segments.some(({ segmentStatus }) => isExpired(segmentStatus));
    const showSegmentExpireSoonAlert = segments.some(({ expiryDate, segmentStatus }) =>
      isSoonToBeExpired(expiryDate, segmentStatus),
    );
    const segmentDaysRemain = segmentDaysRemaining(segments);

    const showExpiredAlert = showExpiredSegmentAlert || showExpiredAudeinceAlert;
    const showExpireSoonAlert = showSegmentExpireSoonAlert || showAudienceExpireSoonAlert;

    const expiredText = `${showExpiredAudeinceAlert ? audienceExpiredText : ''} ${
      showExpiredAudeinceAlert && showExpiredSegmentAlert ? '\n\n' : ''
    }${showExpiredSegmentAlert ? segmentExpiredText(fromDeal) : ''}`;

    const soonWillBeExpiredText = `${showAudienceExpireSoonAlert ? audienceSoonWillBeExpiredText : ''} ${
      showAudienceExpireSoonAlert && showSegmentExpireSoonAlert ? '\n\n' : ''
    } ${showSegmentExpireSoonAlert ? segmentSoonWillBeExpired(fromDeal, segmentDaysRemain) : ''}`;

    return (
      <div className={flex}>
        <p className={marginRight}>{name}</p>
        {showExpiredAlert && (
          <AlertTooltip color="#D83158" title={expiredText} icon={<Error fontSize="small" color="inherit" />} />
        )}
        {showExpireSoonAlert && (
          <AlertTooltip
            color="#E8B046"
            title={soonWillBeExpiredText}
            icon={<Warning fontSize="small" color="inherit" />}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
