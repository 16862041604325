import { ButtonColor } from '@openx/components/core';
import { ReactNode, SyntheticEvent } from 'react';

import { SortDirection } from './sort';
import {
  ChangeCriteriaHandler,
  CriteriaDimension,
  ChangeSingleCriteriaAction,
  PaginationCriteria,
  SortCriteria,
  TableCriteria,
  CriteriaChange,
  AllTableCriteria,
} from './tableCriteria';

export interface BaseRow {
  id?: string;
  expanded?: boolean;
}

export type RowAction<RowT extends BaseRow> = (row: RowT, e: SyntheticEvent) => void;

export type RowActionState<RowT extends BaseRow> = ((row: RowT) => boolean) | boolean;

export interface RowActionBase<RowT extends BaseRow> {
  label: string | ((row: RowT) => string);
  onClick: RowAction<RowT>;
  onHover?: () => void;
  allowed?: RowActionState<RowT>;
  disabled?: RowActionState<RowT>;
  loading?: RowActionState<RowT>;
  notAllowedMessage?: string | ((row: RowT) => string);
  'data-test'?: string;
}

export interface RowActionButtonBaseProps<RowT extends BaseRow> extends RowActionBase<RowT> {
  notAllowedMessage?: string | ((row: RowT) => string);
  color?: ButtonColor;
}

export interface RowActionProps<RowT extends BaseRow> extends RowActionBase<RowT> {
  topDivider?: boolean;
  danger?: boolean;
}

export function currentSort(column: string, sortConfig?: SortCriteria): SortDirection | undefined {
  if (!sortConfig || sortConfig.column !== column) {
    return undefined;
  }

  return sortConfig.direction;
}

export type Highlighter = (content: string) => ReactNode;

export interface TableColumn<RowT extends BaseRow> {
  title: ReactNode;
  tooltipMessage?: string;
  key: string;
  render?: (row: RowT, highlighter: Highlighter) => ReactNode;
  width?: string;
  verticalPadding?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  multivalue?: boolean;
  multivalueTooltip?: boolean;
  mapBy?: { [id: string]: { name: string } | undefined };
  ellipsis?: boolean;
  ellipsisMaxWidth?: string;
  isDate?: boolean;
}

export type Columns<RowT extends BaseRow> = TableColumn<RowT>[];

export function hasPrevItems(paginationCriteria: PaginationCriteria): boolean {
  return paginationCriteria.pageNumber > 1;
}

export function maxPageNumber(paginationCriteria: PaginationCriteria): number {
  return Math.max(Math.ceil(paginationCriteria.totalCount / paginationCriteria.pageSize), 1);
}

export function hasNextItems(paginationCriteria: PaginationCriteria): boolean {
  return maxPageNumber(paginationCriteria) > paginationCriteria.pageNumber || paginationCriteria.hasMore === true;
}

export function prepareChangeCriteriaHandler<D extends CriteriaDimension, FiltersT extends {} = {}>(
  dimension: D,
  onChange?: ChangeCriteriaHandler<CriteriaDimension, FiltersT>,
): ChangeSingleCriteriaAction<D> {
  return (newCriteria: TableCriteria<D>) => {
    onChange &&
      onChange({
        dimension,
        value: newCriteria,
      } as CriteriaChange<D, FiltersT>);
  };
}

export function hasToLoadMore(
  requestPageNumber: number,
  pagination?: TableCriteria<CriteriaDimension.PAGINATION>,
): boolean {
  if (!pagination) {
    return false;
  }

  return requestPageNumber > maxPageNumber(pagination) && hasNextItems(pagination);
}

export function adjustPaginationCriteria<FiltersT extends {}>(
  criteria: AllTableCriteria<FiltersT>,
  totalCount: number,
  hasMore?: boolean,
): AllTableCriteria<FiltersT> {
  const previousPaginationCriteria = criteria[CriteriaDimension.PAGINATION];

  if (!previousPaginationCriteria) {
    return criteria;
  }

  const pageNumber =
    hasMore === false
      ? Math.min(maxPageNumber({ ...previousPaginationCriteria, totalCount }), previousPaginationCriteria.pageNumber)
      : previousPaginationCriteria.pageNumber;

  return {
    ...criteria,
    [CriteriaDimension.PAGINATION]: {
      ...previousPaginationCriteria,
      pageNumber,
      totalCount,
      hasMore,
    },
  };
}
