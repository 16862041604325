import React, { useMemo, memo, FC, useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import InfiniteScroll from 'react-infinite-scroller';

import { sortRecursive, EnhancedTreeData } from 'utils';

import { Loader } from '@openx/components/core';
import { makeStyles } from '@material-ui/core';

import { Item } from './Item';
import { Icon } from './Icon';
import { ListType } from '../types';
import { getVisibleItems } from './utils';

const useStyles = makeStyles({
  scrollItems: {
    maxHeight: '280px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100%',
  },
});

interface SelectionListProps {
  items: EnhancedTreeData[];
  type: ListType;
  readonly: boolean;
  onClick?: (item: EnhancedTreeData) => void;
  onDelete: (item: EnhancedTreeData) => void;
}

const mapTypeToActionLabel = {
  [ListType.INCLUDE]: 'EXCLUDE',
  [ListType.EXCLUDE]: 'INCLUDE',
};

export const SelectionList: FC<SelectionListProps> = memo(
  ({ type, items, onClick, onDelete, readonly }): JSX.Element => {
    const { scrollItems } = useStyles();

    const sortedItems = useMemo(() => sortRecursive(items), [items]);

    const [listFolded, setListFolded] = useState(false);
    const [visibleItems, setVisibleItems] = useState<EnhancedTreeData[]>([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
      setVisibleItems(getVisibleItems({ items: sortedItems, listFolded, page }));
    }, [listFolded, sortedItems, page]);

    const foldedItemsCount = useMemo(() => sortedItems.length - visibleItems.length, [sortedItems, visibleItems]);

    useEffect(() => {
      const newVisibleItems = getVisibleItems({ items: sortedItems, listFolded, page });

      if (!isEqual(visibleItems, newVisibleItems)) {
        setVisibleItems(newVisibleItems);
      }
    }, [listFolded, sortedItems, page, visibleItems]);

    const loadMore = currentPage => {
      setPage(currentPage);
    };

    return (
      <div className={scrollItems}>
        <InfiniteScroll
          pageStart={0}
          loadMore={listFolded ? () => {} : loadMore}
          hasMore={listFolded ? false : foldedItemsCount > 0}
          loader={<Loader />}
          threshold={100}
          useWindow={false}
        >
          {visibleItems.map(item => (
            <Item
              key={item.id}
              item={item}
              onDelete={onDelete}
              onClick={onClick}
              clickLabel={mapTypeToActionLabel[type]}
              icon={<Icon type={type} />}
              readonly={readonly}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  },
  (prevProps, nextProps) => {
    const itemsChanged = isEqual(prevProps.items, nextProps.items);
    const typeChanged = prevProps.type !== nextProps.type;

    return itemsChanged && typeChanged;
  },
);
