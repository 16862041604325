import React, { useCallback, useState, FC } from 'react';
import { Typography, Theme, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loader, Button } from '@openx/components/core';

import {
  useAudience,
  ActionType,
  DIRECT_SEGMENT_FIELD,
  FIRST_PARTY_FIELD,
  getDirectProvider,
  getOpenSubgroupDirectSegment,
  getOpenSubgroupFirstParty,
  getAccountId,
} from 'modules/audiences/AudienceProvider';
import { useProviders, useDirectOptions } from 'modules/audiences/hooks';
import { EnhancedTreeData } from 'utils';
import { IncludeExcludeDropdown } from 'modules/audiences/components/IncludeExcludeDropdown';

import { BulkAdd } from './BulkAdd';
import { DEFAULT_PROVIDER_ID } from 'modules/audiences/hooks/useProviders';

import { useFirstPartyOptions } from 'modules/audiences/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px 0 20px',
  },
  listTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(),
  },
  form: {
    marginTop: '10px',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: '20px',
  },
  divider: {
    marginTop: '15px',
  },
  bulkUpload: {
    marginTop: '17px',
    height: 28,
  },
}));

export const DirectSegment: FC = (): JSX.Element | null => {
  const classes = useStyles();
  const { state, dispatch } = useAudience();
  const { getProviderName } = useProviders();
  const [openBulkAction, setOpenBulkAction] = useState(false);

  const directProvider = getDirectProvider(state);
  const directProviderName = getProviderName(directProvider);
  const accountId = getAccountId(state);
  const directSegment = getOpenSubgroupDirectSegment(state);

  const selectedFirstParty = getOpenSubgroupFirstParty(state);

  const { loading, error, options } = useDirectOptions({ directProvider, directSegment });

  const { loading: upLoading, error: upError, options: upOptions } = useFirstPartyOptions({ accountId });

  const handleSelectChange = useCallback(
    (option: EnhancedTreeData) => {
      dispatch({
        type: ActionType.SET_DIRECT_SEGMENT,
        payload: {
          segments: { [DIRECT_SEGMENT_FIELD]: [...directSegment, option] },
        },
      });
    },
    [dispatch, directSegment],
  );

  const handleUploadSelectChange = useCallback(
    (option: EnhancedTreeData) => {
      const newFirstPraty = [...selectedFirstParty, option];
      dispatch({
        type: ActionType.SET_FIRST_PARTY,
        payload: { segments: { [FIRST_PARTY_FIELD]: newFirstPraty } },
      });
    },
    [dispatch, selectedFirstParty],
  );

  const setBulkDirectSegments = useCallback(
    newSegments => {
      const newInclude = [...directSegment, ...newSegments];
      const uniqueIds = [...new Set(newInclude.map(({ id }) => id))];
      const uniqueSegments = uniqueIds.map(id => newInclude.find(el => el.id === id));

      dispatch({
        type: ActionType.SET_DIRECT_SEGMENT,
        payload: {
          segments: { [DIRECT_SEGMENT_FIELD]: uniqueSegments },
        },
      });
    },
    [dispatch, directSegment],
  );

  const onOpenBulkAction = () => {
    setOpenBulkAction(true);
  };

  const onCloseBulkAction = () => {
    setOpenBulkAction(false);
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }

  if (error) {
    throw new Error('Query Error');
  }

  return (
    <div className={classes.root} data-test="direct-audience-paper">
      <Typography variant="h3" gutterBottom data-test="direct-audience-title" className={classes.title}>
        Direct Audience Segments
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={9} data-test="direct-segment-dropdown">
          <IncludeExcludeDropdown
            data-test="direct-segment-dropdown"
            label={`${directProviderName} Segments`}
            placeholder={`[ Select ${directProviderName} Segment ]`}
            onClick={handleSelectChange}
            options={options}
            selected={directSegment}
          />
        </Grid>
        <Grid item xs={2}>
          <Button className={classes.bulkUpload} size="small" data-test="bulk-upload-button" onClick={onOpenBulkAction}>
            BULK ADD
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.form}>
          <IncludeExcludeDropdown
            label="Uploaded Segments"
            placeholder="[ Select Uploaded Segment ]"
            onClick={handleUploadSelectChange}
            options={upOptions}
            selected={selectedFirstParty}
          />
        </div>
      </Grid>
      <Divider className={classes.divider} />
      <BulkAdd
        setDirectSegments={setBulkDirectSegments}
        onClose={onCloseBulkAction}
        isOpen={openBulkAction}
        directProvider={directProvider || DEFAULT_PROVIDER_ID}
      />
    </div>
  );
};
