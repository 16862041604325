import React, { useState, useCallback, useMemo, FC, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { Paper } from '@openx/components/core';
import {
  Table,
  SortDirection,
  applyCriteriaChange,
  applySelectionChange,
  AllTableCriteria,
  SelectableTable,
  SelectOperation,
  SearchFiltersValue,
  deriveSegmentStatusValue,
  serverSideCriteriaProcessing,
  QueryVar,
} from '@openx/components/table';
import GetSegmentsAccounts from 'graphql/query/segments/GetSegmentsAccounts.gql';
import GetSegmentsTotal from 'graphql/query/segments/GetSegmentsTotal.gql';
import GetSegmentFeed from 'graphql/query/segments/GetSegmentFeed.gql';
import {
  Account,
  GetSegmentFeedQuery,
  GetSegmentFeedQueryVariables,
  GetSegmentsAccountsQuery,
  GetSegmentsAccountsQueryVariables,
  GetSegmentsTotalQuery,
  GetSegmentsTotalQueryVariables,
  Segment,
  Provider,
} from 'types/schemaTypes';
import { TABLE_PAGE_SIZE, TOP_BAR_HEIGHT } from 'config';
import { useProviders } from 'modules/audiences/hooks';
import { useSegmentStatuses } from 'modules/segments/hooks';
import { SearchResultPlaceholder } from 'components/TablePlaceholder/SearchResultsPlaceholder';
import { EmptyTablePlaceholder } from 'components/TablePlaceholder/EmptyTablePlaceholder';
import { useSession } from 'modules/auth/AuthProvider';
import { isInternalAustralianUser, isInternalUser } from 'permissions';

import { ArchiveConfirmationDialog } from './ArchiveConfirmationDialog';
import { RenameDialog } from './RenameDialog';
import { SegmentsHeader } from './SegmentsHeader';
import { listTableColumns } from './tableColumns';
import { UploadFileDialog } from './UploadFile';
import { isDeepEqual } from 'utils';
import { statusCriteriaProcessing } from 'utils';

export type SegmentRow = Pick<
  Segment,
  'id' | 'name' | 'reach' | 'status' | 'created_date' | 'updated_date' | 'expiration_date'
> & { account?: Pick<Account, 'name'> | null } & { provider?: Pick<Provider, 'name'> | null };

const SegmentsTable = SelectableTable<SegmentRow>(Table);

const initialCriteria: AllTableCriteria = {
  pagination: {
    pageSize: TABLE_PAGE_SIZE,
    pageNumber: 1,
    totalCount: 100,
  },
  sort: {
    column: 'created_date',
    direction: SortDirection.DESC,
    valueDerivers: {
      status: deriveSegmentStatusValue,
      account: (account: Account): string => account.name,
      provider: (provider: Provider): string => provider.name,
    },
    sortableColumns: ['name', 'reach', 'status', 'provider', 'account', 'created_date', 'updated_date'],
  },
  filters: {
    byPhrase: {
      fields: ['name'],
      phrase: '',
    },
  },
};

interface ConfirmationDialogState {
  data?: SegmentRow[];
  open: boolean;
}

const confirmationDialogInitialState = {
  open: false,
};

export const SegmentsList: FC = (): JSX.Element => {
  const session = useSession();

  const isInternalOxUser = useMemo(() => isInternalAustralianUser(session) || isInternalUser(session), [session]);

  const [criteria, setCriteria] = useState<AllTableCriteria>(applyCriteriaChange(initialCriteria));

  const { loadingProviders, getProviderName, providers } = useProviders();

  const {
    loading: accountLoading,
    error: accountError,
    data: { account: segmentsAccounts = [] } = {},
  } = useQuery<GetSegmentsAccountsQuery, GetSegmentsAccountsQueryVariables>(GetSegmentsAccounts);

  type QueryParams = GetSegmentFeedQueryVariables & QueryVar;

  const criteriaProcessed = useMemo(() => statusCriteriaProcessing(criteria, 'expiration_date'), [criteria]);

  const queryCrit = serverSideCriteriaProcessing<QueryParams>(criteriaProcessed, initialCriteria.sort);

  const {
    loading: countLoading,
    error: countError,
    data: { segment_aggregate } = {},
  } = useQuery<GetSegmentsTotalQuery, GetSegmentsTotalQueryVariables>(GetSegmentsTotal, {
    variables: queryCrit,
  });

  if (
    !countLoading &&
    typeof criteria.pagination?.totalCount !== 'undefined' &&
    typeof segment_aggregate?.aggregate?.count !== 'undefined'
  )
    criteria.pagination.totalCount = segment_aggregate.aggregate.count;

  const count = criteria?.pagination?.totalCount ?? 0;

  const {
    loading: segmentLoading,
    error: segmentError,
    data: { segmentFeed = [] } = {},
    refetch,
    fetchMore,
  } = useQuery<GetSegmentFeedQuery, GetSegmentFeedQueryVariables>(GetSegmentFeed, {
    variables: queryCrit,
  });

  const loading = segmentLoading || accountLoading || countLoading || loadingProviders;
  const error = segmentError ?? accountError ?? countError;
  const { uiSegmentStatuses } = useSegmentStatuses();

  const [selectedSegments, setSelectedSegments] = useState<SegmentRow[]>([]);

  const [archiveConfirmationDialog, setArchiveConfirmationDialog] =
    useState<ConfirmationDialogState>(confirmationDialogInitialState);

  const [segmentToRename, setSegmentToRename] = useState<Segment | null>(null);

  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);

  useEffect(() => {
    const preloadOffset = (criteria.pagination?.pageSize ?? 1) * (criteria.pagination?.pageNumber ?? 1);
    let timeoutId: NodeJS.Timeout | null = setTimeout(() => {
      if (preloadOffset < count && !loading && !error) {
        fetchMore({
          variables: {
            offset: preloadOffset,
          },
        });
      }
      timeoutId = null;
    }, 500);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [criteria, count, loading, error, fetchMore]);

  if (error) throw new Error('Segments Error');
  const handleCriteriaChange = useCallback(
    newCriteria => {
      if (
        newCriteria?.dimension === 'sort' &&
        newCriteria.value?.column === criteria.sort?.column &&
        newCriteria.value?.direction === 'asc' &&
        newCriteria.value?.column !== initialCriteria.sort?.column
      ) {
        newCriteria.value.column = initialCriteria.sort?.column;
        newCriteria.value.direction = initialCriteria.sort?.direction;
      }

      const newCriteriaProcessed = applyCriteriaChange(criteria, newCriteria);

      if (!isDeepEqual({ ...newCriteriaProcessed }, { ...criteria })) {
        setCriteria(newCriteriaProcessed);
      }
    },
    [criteria],
  );

  const handleSelectionChange = useCallback(
    (selectedItems, operation) => {
      setSelectedSegments(applySelectionChange(selectedSegments, selectedItems, operation));
    },
    [selectedSegments],
  );

  const columns = useMemo(
    () => (isInternalOxUser ? listTableColumns : listTableColumns.filter(({ title }) => title !== 'Organizations')),
    [isInternalOxUser],
  );

  const openArchiveConfirmationDialogForSegments = useCallback(
    data => {
      setArchiveConfirmationDialog({ data, open: true });
    },
    [setArchiveConfirmationDialog],
  );

  const closeArchiveConfirmationDialog = useCallback(
    (success: boolean) => {
      const { data } = archiveConfirmationDialog;
      setArchiveConfirmationDialog({ open: false });
      if (success) {
        refetch();
        handleSelectionChange(data, SelectOperation.UNSELECT);
      }
    },
    [archiveConfirmationDialog, handleSelectionChange, refetch, setArchiveConfirmationDialog],
  );

  const closeRenameDialog = useCallback(
    (success: boolean) => {
      setSegmentToRename(null);
      if (success) {
        refetch();
      }
    },
    [refetch, setSegmentToRename],
  );

  const openUploadFileDialog = useCallback(() => {
    setUploadFileDialogOpen(true);
  }, []);

  const closeUploadFileDialog = useCallback(
    (success: boolean) => {
      setUploadFileDialogOpen(false);
      if (success) {
        refetch();
      }
    },
    [refetch],
  );

  const rowActions = useMemo(
    () => [
      {
        label: 'Rename',
        onClick: segment => {
          setSegmentToRename(segment);
        },
        'data-test': 'segment-rename',
      },
      {
        label: 'Archive',
        onClick: segment => {
          openArchiveConfirmationDialogForSegments([segment]);
        },
        topDivider: true,
        danger: true,
        'data-test': 'segment-archive',
      },
    ],
    [openArchiveConfirmationDialogForSegments, setSegmentToRename],
  );

  const isTableEmpty = !loading && !criteria.filters && segmentFeed.length === 0;

  const noSearchResults = !loading && criteria.filters && segmentFeed.length === 0;

  const accountOptions = segmentsAccounts.map(({ name }) => name);

  const action = useCallback(segments => {
    openArchiveConfirmationDialogForSegments(segments);
  }, []);

  const bulkActions = useMemo(
    () => [
      {
        label: 'Bulk Archive',
        action,
      },
    ],
    [action],
  );

  if (isTableEmpty || loading) {
    return (
      <SegmentsHeader
        handleCriteriaChange={handleCriteriaChange}
        onUploadFile={openUploadFileDialog}
        statuses={uiSegmentStatuses}
        providers={providers.map(({ name }) => name)}
        segmentAccounts={accountOptions}
        isInternalOxUser={isInternalOxUser}
      >
        <Paper padding="none">
          {loading ? (
            <SegmentsTable
              data={[]}
              columns={columns}
              criteria={criteria}
              loading={loading || loadingProviders}
              highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
              stickyHeaderPosition={TOP_BAR_HEIGHT}
              selectedItems={selectedSegments}
              toggleSelect={() => void 0}
              rowActions={rowActions}
              fixedLayout
              bulkActions={bulkActions}
            ></SegmentsTable>
          ) : (
            <EmptyTablePlaceholder />
          )}
        </Paper>
      </SegmentsHeader>
    );
  }

  return (
    <>
      <SegmentsHeader
        handleCriteriaChange={handleCriteriaChange}
        onUploadFile={openUploadFileDialog}
        statuses={uiSegmentStatuses}
        providers={providers.map(({ name }) => name)}
        segmentAccounts={accountOptions}
        isInternalOxUser={isInternalOxUser}
      >
        <Paper padding="none">
          <SegmentsTable
            data={segmentFeed}
            columns={columns}
            criteria={criteria}
            loading={loading || loadingProviders}
            onCriteriaChange={handleCriteriaChange}
            highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
            stickyHeaderPosition={TOP_BAR_HEIGHT}
            selectedItems={selectedSegments}
            toggleSelect={handleSelectionChange}
            rowActions={rowActions}
            fixedLayout
            bulkActions={bulkActions}
          >
            {noSearchResults && <SearchResultPlaceholder columnsAmount={listTableColumns.length + 2} />}
          </SegmentsTable>
        </Paper>
      </SegmentsHeader>

      <ArchiveConfirmationDialog
        onClose={closeArchiveConfirmationDialog}
        data={archiveConfirmationDialog.data}
        open={archiveConfirmationDialog.open}
      />

      {segmentToRename && <RenameDialog onClose={closeRenameDialog} segment={segmentToRename} />}

      <UploadFileDialog open={uploadFileDialogOpen} onClose={closeUploadFileDialog} />
    </>
  );
};
