import React from 'react';
import { DateCell } from 'components/DateCell';
import { Columns, TableColumn } from '@openx/components/table';
import { StatusIndicator } from '@openx/components/core';
import { mapStatus } from './utils';
import { abbreviatedNumber } from '@openx/utils';

import { ReachTooltip } from './ReachTooltip';
import { SegmentRow } from './SegmentsList';
import { SegmentCell } from '../components/SegmentCell';

const tableNameColumn: TableColumn<SegmentRow> = {
  title: 'Name',
  key: 'name',
  verticalPadding: '13px',
  render: ({ name, expiration_date, status }) => {
    if (!name) return null;

    return <SegmentCell name={name} estimatedExpiryDate={expiration_date} status={status} />;
  },
};

const tableReachColumn: TableColumn<SegmentRow> = {
  title: 'Segment Reach',
  key: 'reach',
  render: ({ id, reach }) =>
    reach ? (
      <div style={{ display: 'flex' }}>
        <span>{abbreviatedNumber(reach)}</span>
        <ReachTooltip id={id} reach={reach} />
      </div>
    ) : null,
};

const tableCreatedDateColumn: TableColumn<SegmentRow> = {
  title: 'Date Created',
  key: 'created_date',
  render: ({ created_date }) => <DateCell date={created_date} />,
};

const tableUpdatedDateColumn: TableColumn<SegmentRow> = {
  title: 'Date Updated',
  key: 'updated_date',
  render: ({ updated_date }) => <DateCell date={updated_date} />,
};

export const listTableColumns: Columns<SegmentRow> = [
  {
    ...tableNameColumn,
    width: '35%',
  },
  {
    ...tableReachColumn,
    width: '15%',
  },
  {
    title: 'Status',
    key: 'status',
    width: '10%',
    render: ({ status: itemStatus }) => {
      const status = mapStatus(itemStatus);

      if (!status) {
        return null;
      }

      return <StatusIndicator label={status.label} status={status.status} />;
    },
  },
  {
    title: 'Provider',
    key: 'provider',
    width: '10%',
    render: ({ provider }) => (provider ? provider.name : null),
  },
  {
    title: 'Organization',
    key: 'account',
    width: '10%',
    render: ({ account }) => (account ? account.name : null),
  },
  {
    ...tableUpdatedDateColumn,
    width: '10%',
  },
  {
    ...tableCreatedDateColumn,
    width: '10%',
  },
];

export const confirmationDialogTableColumns: Columns<SegmentRow> = [
  {
    ...tableNameColumn,
    width: '30%',
  },
  {
    ...tableReachColumn,
    width: '30%',
  },
  {
    ...tableUpdatedDateColumn,
    width: '15%',
  },
  {
    ...tableCreatedDateColumn,
    width: '15%',
  },
];
