import { useApolloClient, useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { Loader } from '@openx/components/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SKIP_AUTH } from 'config';
import { fetchAuthToken, login, logout, useFirebaseListener } from 'firebaseIntegration';
import CheckUserPermissions from 'graphql/query/CheckUserPermissions.gql';
import GetSession from 'graphql/query/GetSession.gql';
import { CheckUserPermissionsQuery, CheckUserPermissionsQueryVariables } from 'types/schemaTypes';

import { authContext } from './authContext';
import { mapOfAuthStateToAction } from './mapOfAuthStateToAction';
import { sessionContext } from './sessionContext';
import { AuthContext, AuthProviderProps, AuthState, SessionContext } from './types';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const publicPages = ['/login', '/forgot-password', '/set-password', '/terms'];

export function AuthProvider({ children }: AuthProviderProps) {
  const classes = useStyles();
  const firebaseListener = useFirebaseListener();
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();
  const [authState, setAuthState] = useState<AuthState>(AuthState.UNAUTHENTICATED);

  const [triggerCheckPermissionsQuery, checkPermissionQuery] = useLazyQuery<
    CheckUserPermissionsQuery,
    CheckUserPermissionsQueryVariables
  >(CheckUserPermissions);
  const [triggerSessionQuery, sessionQuery] = useLazyQuery(GetSession, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const authContextValue = useMemo<AuthContext>(
    () => ({
      login: async ({ email, password, redir }) => {
        await login({ email, password });
        if (redir?.startsWith('/')) {
          const token = await fetchAuthToken();
          window.location.href = `${redir}?token=${token}`;
        } else {
          history.push('/');
        }
      },
      reloadSession: () => {
        sessionQuery.refetch?.();
        setAuthState(AuthState.SESSION_STALE);
      },
      logout: () => {
        Promise.all([logout(), client.stop(), client.clearStore(), firebaseListener.reset()]).then(() => {
          setAuthState(AuthState.UNAUTHENTICATED);
        });
      },
    }),
    [client, firebaseListener, history, sessionQuery],
  );

  const sessionContextValue = useMemo<SessionContext>(
    () => (authState === AuthState.UNAUTHENTICATED ? {} : sessionQuery.data?.getSession || {}),
    [sessionQuery.data, authState],
  );

  const pageIsPublic = publicPages.includes(location.pathname);

  useEffect(() => {
    mapOfAuthStateToAction[authState]({
      checkPermissionQuery,
      currentPage: location.pathname,
      fetchAuthToken,
      firebaseListener,
      history,
      pageIsPublic,
      sessionContextValue,
      sessionQuery,
      setAuthState,
      skipAuth: SKIP_AUTH,
      triggerCheckPermissionsQuery,
      triggerSessionQuery,
    });
  }, [
    authState,
    checkPermissionQuery,
    firebaseListener,
    history,
    location.pathname,
    pageIsPublic,
    sessionContextValue,
    sessionQuery,
    triggerCheckPermissionsQuery,
    triggerSessionQuery,
  ]);

  if (checkPermissionQuery.error || sessionQuery.error) {
    throw new Error(
      `Cannot fully authenticate user ${JSON.stringify(checkPermissionQuery.error)} ${JSON.stringify(
        sessionQuery.error,
      )}`,
    );
  }

  const termsNotAcceptedAndForbiddenRoute =
    !sessionContextValue.terms_and_conditions_accepted && location.pathname !== '/terms-acceptance';

  if (
    !publicPages.includes(location.pathname) &&
    (authState !== AuthState.SESSION_OBTAINED || termsNotAcceptedAndForbiddenRoute)
  ) {
    return (
      <div className={classes.container}>
        <Loader data-test="loader" />
      </div>
    );
  }

  return (
    <authContext.Provider value={authContextValue}>
      <sessionContext.Provider value={sessionContextValue}>{children}</sessionContext.Provider>
    </authContext.Provider>
  );
}
