import React, { MouseEvent, ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CSVLink } from 'react-csv';

import { Button, ButtonProps } from '../../Button';
import { DialogTitle } from '../DialogTitle';

const useStyles = makeStyles({
  paperWidthSm: {
    maxWidth: '672px',
  },
});

export interface ConfirmationDialogProps {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirmed: () => void;
  secondaryAction?: () => void;
  onCancel: () => void;
  children: ReactNode;
  confirmLabel?: ReactNode;
  cancelLabel?: ReactNode;
  customContent?: boolean;
  confirmationButtonColor?: ButtonProps['color'];
  confirmationDisabled?: boolean;
  hideConfirm?: boolean;
  secondaryActionAvaliable?: boolean;
  secondaryActionTitle?: string;
  csvButton?: boolean;
  csvProps?: any;
}

export function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
  const {
    title,
    isOpen,
    onConfirmed,
    secondaryAction = () => {},
    onCancel,
    children,
    isLoading,
    confirmLabel = 'Ok',
    cancelLabel = 'Cancel',
    csvButton = false,
    csvProps,
    customContent = false,
    confirmationButtonColor = 'primary',
    confirmationDisabled = false,
    hideConfirm = false,
    secondaryActionAvaliable,
    secondaryActionTitle,
  } = props;

  const { paperWidthSm } = useStyles();

  const handleOkButton = (event: MouseEvent): void => {
    event.stopPropagation();
    onConfirmed();
  };

  const handleSecondaryButton = (event: MouseEvent): void => {
    event.stopPropagation();
    secondaryAction();
  };

  const handleCancelButton = (event: MouseEvent): void => {
    event.stopPropagation();
    onCancel();
  };

  const handleClose = (e, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      e.stopPropagation();
    }

    onCancel();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      data-test={props['data-test']}
      onClick={e => e.stopPropagation()}
      classes={{ paperWidthSm }}
    >
      <DialogTitle dataTest="confirmation-dialog-title" onClose={onCancel} onCloseDisabled={isLoading}>
        {title}
      </DialogTitle>
      {customContent ? (
        children
      ) : (
        <DialogContent data-test="dialog-content" dividers>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions data-test="dialog-actions">
        <Button onClick={handleCancelButton} variant="text" disabled={isLoading} data-test="cancel-button">
          {cancelLabel}
        </Button>
        {!hideConfirm && !secondaryActionAvaliable && csvButton && (
          <Button
            onClick={handleOkButton}
            color={confirmationButtonColor}
            disabled={isLoading || confirmationDisabled}
            loading={isLoading}
            autoFocus
            data-test="submit-csv-button"
          >
            <CSVLink {...csvProps} target="_blank">
              {confirmLabel}
            </CSVLink>
          </Button>
        )}
        {!hideConfirm && !secondaryActionAvaliable && !csvButton && (
          <Button
            onClick={handleOkButton}
            color={confirmationButtonColor}
            disabled={isLoading || confirmationDisabled}
            loading={isLoading}
            autoFocus
            data-test="submit-button"
          >
            {confirmLabel}
          </Button>
        )}
        {!hideConfirm && secondaryActionAvaliable && !csvButton && (
          <>
            <Button
              onClick={handleSecondaryButton}
              color={confirmationButtonColor}
              disabled={isLoading || confirmationDisabled}
              loading={isLoading}
              autoFocus
              data-test="submit-button-without-reactivate"
            >
              {confirmLabel}
            </Button>
            <Button
              onClick={handleOkButton}
              color="secondary"
              disabled={isLoading || confirmationDisabled}
              loading={isLoading}
              autoFocus
              data-test="submit-button"
            >
              {secondaryActionTitle}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
