import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';

import { Tooltip } from '@openx/components/core';

interface StyleProps {
  color?: string;
}

const useStyles = makeStyles({
  iconButton: ({ color }: StyleProps) => ({
    padding: '6.5px',
    margin: 0,
    color,
  }),
});

export const AlertTooltip = ({ title, icon, color }) => {
  const { iconButton } = useStyles({ color });

  return (
    <Tooltip title={title}>
      <IconButton className={iconButton}>{icon}</IconButton>
    </Tooltip>
  );
};
