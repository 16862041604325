import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UserPanelProps } from '@openx/components/core';
import { useHistory } from 'react-router-dom';

import { privateConfig, Routes } from 'routes';
import { NavigationBar } from 'components/Navigation/NavigationBar';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { TOP_BAR_HEIGHT } from 'config';
import { useSession } from 'modules/auth/AuthProvider';
import { useAuth } from 'modules/auth/AuthProvider';
import { SideDrawerProvider } from 'context';

const useStyles = makeStyles({
  errorBoundary: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  },
});

function getUserPanelProps(session, logout, redirectToTerms): UserPanelProps | undefined {
  if (!session) {
    return undefined;
  }

  const getUserName = ({ first_name, last_name, email }): string =>
    first_name && last_name ? `${first_name} ${last_name}` : email;

  const getAccountName = ({ displayName, account: { name } }): string => displayName || name;

  return {
    userName: getUserName(session),
    accountName: getAccountName(session),
    hideUserName: true,
    options: [
      {
        label: 'Terms and Conditions',
        action: () => {
          redirectToTerms();
        },
      },
      {
        label: 'Logout',
        action: () => {
          logout();
        },
        warn: true,
      },
    ],
  };
}

export function Dashboard(): JSX.Element {
  const classes = useStyles();
  const { logout } = useAuth();
  const history = useHistory();
  const session = useSession();

  const redirectToTerms = useCallback(() => {
    history.push('/terms');
  }, [history]);

  const userPanelProps = useMemo(
    () => getUserPanelProps(session, logout, redirectToTerms),
    [session, logout, redirectToTerms],
  );

  return (
    <SideDrawerProvider>
      <NavigationBar userPanelProps={userPanelProps} topHeight={TOP_BAR_HEIGHT} />
      <ErrorBoundary className={classes.errorBoundary}>
        <Routes routes={privateConfig} />
      </ErrorBoundary>
    </SideDrawerProvider>
  );
}
