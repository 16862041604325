import { PageNotFound } from 'modules/auth/PageNotFound';
import { AudiencesList, AudienceCreate, AudienceEdit, AudienceDetails } from 'modules/audiences';
import { SegmentsList } from 'modules/segments';
import { DEFAULT_PAGE } from 'config';
import { isAdmin } from 'permissions';
import { UserCreate, UsersList } from 'modules/users';
import { OrganizationsList, OrganizationCreate, OrganizationEdit } from 'modules/organizations';
import { TaxonomyList } from 'modules/taxonomy';
import { GraphiQLPage } from 'modules/auth/GraphiQLPage';
import EmbeddedSandbox from 'modules/graphql/EmbeddedSandbox';
import { TermsAcceptance } from '../modules/terms';

import { RouteConfig } from './types';
import { DealsList } from 'modules/deals';
import { OrganizationDetails } from 'modules/organizations/OrganizationDetails';
import { SettingsPage } from 'modules/settings';
import { ProviderCreate, ProviderEdit, ProviderDetails, ProvidersList } from 'modules/providers';

export const privateConfig: RouteConfig[] = [
  {
    path: '/',
    redirectTo: DEFAULT_PAGE,
    exact: true,
  },
  {
    path: '/audiences',
    component: AudiencesList,
    exact: true,
  },
  {
    path: '/audiences/create',
    component: AudienceCreate,
    exact: true,
  },
  {
    path: '/audiences/:audienceId([\\w-]+)',
    component: AudienceDetails,
    exact: true,
  },
  {
    path: '/audiences/:audienceId([\\w-]+)/edit',
    component: AudienceEdit,
    exact: true,
  },
  {
    path: '/segments',
    component: SegmentsList,
    exact: true,
  },
  {
    path: '/taxonomy',
    component: TaxonomyList,
    exact: true,
  },
  {
    path: '/organizations',
    component: OrganizationsList,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/organizations/create',
    component: OrganizationCreate,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/organizations/:organizationId',
    component: OrganizationDetails,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/organizations/:organizationId/edit',
    component: OrganizationEdit,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/providers',
    component: ProvidersList,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/providers/create',
    component: ProviderCreate,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/providers/:providerId',
    component: ProviderDetails,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/providers/:providerId/edit',
    component: ProviderEdit,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/users',
    component: UsersList,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/users/create',
    component: UserCreate,
    exact: true,
    permissionValidators: [isAdmin],
  },
  {
    path: '/deals',
    component: DealsList,
    exact: true,
  },
  {
    path: '/deals/create',
    component: DealsList,
  },
  {
    path: '/deals/edit/:dealId([\\w-]+)/details',
    component: DealsList,
    exact: true,
  },
  {
    path: '/deals/edit/:dealId([\\w-]+)/traffic-filters',
    component: DealsList,
    exact: true,
  },
  {
    path: '/deals/edit/:dealId([\\w-]+)/audience',
    component: DealsList,
    exact: true,
  },
  {
    path: '/deals/:dealId([\\w-]+)',
    component: DealsList,
  },
  {
    path: '/terms-acceptance',
    component: TermsAcceptance,
  },
  {
    path: '/settings',
    component: SettingsPage,
    exact: true,
  },
  {
    path: '/graphiql',
    component: GraphiQLPage,
    permissionValidators: [isAdmin],
  },
  {
    path: '/sandbox',
    component: EmbeddedSandbox,
    permissionValidators: [isAdmin],
  },
  {
    path: '*',
    component: PageNotFound,
  },
];
