import React, { ReactNode } from 'react';

import { Typography } from '@material-ui/core';
import { HoverableItem } from 'components/OptionsList';
import { Warning, Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { AlertTooltip } from 'components/AlertTooltip';

import {
  isExpired,
  isSoonToBeExpired,
  daysToExpiration,
  segmentExpiredText,
  segmentSoonWillBeExpired,
} from 'utils/expiryDates';

interface Props<K> {
  item: K;
  icon: ReactNode;
  indentationLevel?: number;
  onClick?: (item: K) => void;
  clickLabel: string;
  onDelete: (item: K) => void;
  title?: ReactNode;
  readonly?: boolean;
  isNavOnly?: boolean;
}

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '5px',
  },
});

export function Item<K extends { title?: string; name?: string; status?: string; expiration_date?: string | null }>(
  props: Props<K>,
): JSX.Element {
  const {
    icon,
    item,
    onClick,
    clickLabel,
    onDelete,
    title,
    indentationLevel = 0,
    isNavOnly = false,
    readonly = false,
  } = props;

  const { flex, marginRight } = useStyles({});

  const { status, expiration_date: expirationDate } = item;

  const showExpiredAlert = isExpired(status) && !isNavOnly;
  const showExpireSoonAlert = isSoonToBeExpired(expirationDate, status);
  const daysRemaining = daysToExpiration(expirationDate);

  const expiredText = segmentExpiredText(false);
  const soonWillBeExpiredText = segmentSoonWillBeExpired(false, daysRemaining);

  return (
    <HoverableItem
      icon={icon}
      indentationLevel={indentationLevel}
      onClick={onClick ? () => onClick(item) : undefined}
      onDelete={() => onDelete(item)}
      clickLabel={clickLabel}
      readonly={readonly}
      isNavOnly={isNavOnly}
    >
      <div className={flex}>
        <Typography variant="body1" data-test="included-excluded-item" className={marginRight}>
          {title || item.title || item.name}
        </Typography>
        {showExpiredAlert && (
          <AlertTooltip
            color="#D83158"
            title={expiredText}
            icon={<Error fontSize="small" color="inherit" data-test="expired-alert" />}
          />
        )}
        {showExpireSoonAlert && (
          <AlertTooltip
            color="#E8B046"
            title={soonWillBeExpiredText}
            icon={<Warning fontSize="small" color="inherit" />}
          />
        )}
      </div>
    </HoverableItem>
  );
}
