import React, { useState, useCallback, useMemo, useEffect, ChangeEvent } from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import { Loader, Paper, SwitchField } from '@openx/components/core';
import {
  Table,
  SortDirection,
  applyCriteriaChange,
  applySelectionChange,
  AllTableCriteria,
  SearchFiltersValue,
  SelectableTable,
  SelectOperation,
  deriveAudienceStatusValue,
  serverSideCriteriaProcessing,
  QueryVar,
  FiltersT,
} from '@openx/components/table';

import GetAudienceAccounts from 'graphql/query/audiences/GetAudienceAccounts.gql';
import GetAudiencesTotal from 'graphql/query/audiences/GetAudiencesTotal.gql';
import GetAudienceFeed from 'graphql/query/audiences/GetAudienceFeed.gql';
import GetOrganizationUsers from 'graphql/query/users/GetUsersList.gql';
import {
  Audience,
  Account,
  Export,
  GetAudiencesTotalQuery,
  GetAudiencesTotalQueryVariables,
  GetAudienceFeedQuery,
  GetAudienceFeedQueryVariables,
  GetAudienceAccountsQuery,
  GetAudienceAccountsQueryVariables,
  GetUsersListQuery,
  GetUsersListQueryVariables,
} from 'types/schemaTypes';
import { TABLE_PAGE_SIZE, TOP_BAR_HEIGHT } from 'config';
import {
  canEditAudience,
  canCreateDealWithAudience,
  notEditableMessage,
  notSupportDealMesssage,
} from 'modules/audiences/utils';
import { useProviders, useAudienceStatuses } from 'modules/audiences/hooks';

import { AudiencesHeader } from './AudiencesHeader';
import { tableColumns } from './tableColumns';
import { ArchiveConfirmationDialog } from './ArchiveConfirmationDialog';
import { BulkShareConfirmationDialog, ShareConfirmationDialog } from 'modules/audiences/components/AudienceShare';
import { AudienceDuplicate } from '../AudienceDuplicate';
import { SearchResultPlaceholder } from 'components/TablePlaceholder/SearchResultsPlaceholder';
import { EmptyTablePlaceholder } from 'components/TablePlaceholder/EmptyTablePlaceholder';
import { TableBulkActions } from './TableBulkActions';
import { useSession } from 'modules/auth/AuthProvider';
import { isAdmin, isInternalAustralianUser, isInternalUser } from 'permissions';
import { withAudience } from '../AudienceProvider';
import isDeepEqual from 'utils/isEqualDeep';
import { useHistoryWithRef } from 'utils/useHistoryWithRef';
import { statusCriteriaProcessing } from 'utils';
import { ComparisonType } from 'types/common';

type AccountWithUid = (Pick<Account, 'name'> & { uid?: string }) | null;

export type AudienceRow = Pick<
  Audience,
  | 'id'
  | 'name'
  | 'addressable_reach'
  | 'status'
  | 'created_date'
  | 'updated_date'
  | 'direct_audience_provider'
  | 'export_type'
  | 'estimated_expiry_date'
> & { account?: AccountWithUid } & { last_export?: Pick<Export, 'status'> | null };

const AudienceTable = SelectableTable<AudienceRow>(Table);

const initialCriteria = {
  pagination: {
    pageSize: TABLE_PAGE_SIZE,
    pageNumber: 1,
    totalCount: 100,
  },
  sort: {
    column: 'updated_date',
    direction: SortDirection.DESC,
    valueDerivers: {
      status: deriveAudienceStatusValue,
      account: (account: Account): string => account.name,
    },
    sortableColumns: [
      'name',
      'addressable_reach',
      'status',
      'direct_audience_provider',
      'account',
      'created_date',
      'updated_date',
    ],
  },
  filters: {
    byPhrase: {
      fields: ['name'],
      phrase: '',
    },
  },
};

interface ConfirmationDialogState {
  data?: AudienceRow[];
  audience?: AudienceRow;
  open: boolean;
}

interface HistoryState {
  fromAudience?: string;
  deal: unknown;
  page?: number;
  ref?: string;
  useAsTemplate?: boolean;
}

const confirmationDialogInitialState = {
  open: false,
};

function AudiencesListContent(): JSX.Element {
  const history = useHistoryWithRef<HistoryState>();
  const session = useSession();

  const [criteria, setCriteria] = useState<AllTableCriteria<FiltersT>>({
    ...initialCriteria,
    pagination: {
      ...initialCriteria.pagination,
      pageNumber: history.location.state?.page ?? 1,
    },
  });

  const isInternalOxUser = isInternalAustralianUser(session) || isInternalUser(session);

  const {
    loading: accountLoading,
    error: accountError,
    data: { account: audienceAccounts = [] } = {},
  } = useQuery<GetAudienceAccountsQuery, GetAudienceAccountsQueryVariables>(GetAudienceAccounts);

  type QueryParams = GetAudiencesTotalQueryVariables & QueryVar;
  const criteriaProcessed = useMemo(() => statusCriteriaProcessing(criteria, 'estimated_expiry_date'), [criteria]);

  const queryCrit = serverSideCriteriaProcessing<QueryParams>(criteriaProcessed, initialCriteria.sort);
  const { loading: countLoading, error: countError } = useQuery<
    GetAudiencesTotalQuery,
    GetAudiencesTotalQueryVariables
  >(GetAudiencesTotal, {
    variables: queryCrit,
    onCompleted({ audience_aggregate }) {
      if (
        typeof criteria.pagination?.totalCount !== 'undefined' &&
        typeof audience_aggregate?.aggregate?.count !== 'undefined'
      ) {
        criteria.pagination.totalCount = audience_aggregate.aggregate.count;
        //setCriteria is necessary here to fix display table items bug
        setCriteria({ ...criteria });
      }
    },
  });

  const count = criteria?.pagination?.totalCount ?? 0;

  const {
    loading: audienceLoading,
    error: audienceError,
    data: { audienceFeed = [] } = {},
    refetch,
    fetchMore,
  } = useQuery<GetAudienceFeedQuery, GetAudienceFeedQueryVariables>(GetAudienceFeed, {
    variables: queryCrit,
  });

  const isInternalAdmin = isInternalOxUser && isAdmin(session);
  const criteriaAccounts =
    criteria.filters?.byValue?.find(byValueFilter => byValueFilter.field === 'account')?.values ?? [];
  const criteriaAccountIds = audienceAccounts
    .filter(account => criteriaAccounts.some(accountName => accountName === account.name))
    .map(account => account.id);

  const organizationIds = !isInternalAdmin
    ? [session?.account_id, ...criteriaAccountIds].filter(val => !!val)
    : undefined;
  const {
    loading: usersLoading,
    error: usersError,
    data: { user: users = [] } = {},
  } = useQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetOrganizationUsers, {
    variables: {
      organizationIds,
    },
  });

  const loading = audienceLoading || countLoading || accountLoading || usersLoading;
  const feedLoaded = !loading && audienceFeed.length > 0;
  const error = audienceError ?? (countError || accountError || usersError);

  const [showMyAudiences, setShowMyAudiences] = useState(false);
  const [selectedAudiences, setSelectedAudiences] = useState<AudienceRow[]>([]);
  const [audienceToDuplicate, setAudienceToDuplicate] = useState<Audience | null>(null);
  const { getProviderName, providers } = useProviders();
  const { uiAudienceStatuses } = useAudienceStatuses();

  const [archiveConfirmationDialog, setArchiveConfirmationDialog] =
    useState<ConfirmationDialogState>(confirmationDialogInitialState);

  const [bulkShareConfirmationDialog, setBulkShareConfirmationDialog] =
    useState<ConfirmationDialogState>(confirmationDialogInitialState);

  const [shareConfirmationDialog, setShareConfirmationDialog] =
    useState<ConfirmationDialogState>(confirmationDialogInitialState);

  const handleCriteriaChange = useCallback(
    (newCriteria, isInitialCall) => {
      if (
        newCriteria?.dimension === 'sort' &&
        newCriteria.value?.column === criteria.sort?.column &&
        newCriteria.value?.direction === 'asc' &&
        newCriteria.value?.column !== initialCriteria.sort?.column
      ) {
        newCriteria.value.column = initialCriteria.sort?.column;
        newCriteria.value.direction = initialCriteria.sort?.direction;
      }

      const newCriteriaProcessed = applyCriteriaChange(criteria, newCriteria, { skipPaginationReset: isInitialCall });

      if (!isDeepEqual({ ...newCriteriaProcessed }, { ...criteria })) {
        const createdByIds = newCriteria.value?.byValue?.find(filter => filter.field === 'created_by')?.values;
        setShowMyAudiences(createdByIds?.includes(session.id));

        setCriteria(newCriteriaProcessed);
      }
    },
    [criteria],
  );

  const handleMyAudiences = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setImmediate(() => {
        setShowMyAudiences(checked);
      });

      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete('users');

      if (checked) {
        const user = `${session.first_name} ${session.last_name} (${session.email_address})`;
        searchParams.append('users', encodeURI(user));
      }

      history.replace(`${history.location.pathname}?${searchParams}`);
    },
    [criteria],
  );

  const handleSelectionChange = useCallback(
    (selectedItems, operation) => {
      setSelectedAudiences(applySelectionChange(selectedAudiences, selectedItems, operation));
    },
    [selectedAudiences],
  );

  const openArchiveConfirmationDialog = useCallback(
    data => {
      setArchiveConfirmationDialog({ data, open: true });
    },
    [setArchiveConfirmationDialog],
  );

  const closeArchiveConfirmationDialog = useCallback(
    (success: boolean) => {
      const { data } = archiveConfirmationDialog;
      setArchiveConfirmationDialog({ open: false });

      if (success) {
        refetch();
        handleSelectionChange(data, SelectOperation.UNSELECT);
      }
    },
    [archiveConfirmationDialog, handleSelectionChange, refetch, setArchiveConfirmationDialog],
  );

  const openBulkShareConfirmationDialog = useCallback(
    data => {
      setBulkShareConfirmationDialog({ data, open: true });
    },
    [setBulkShareConfirmationDialog],
  );

  const openShareConfirmationDialog = useCallback(
    audience => {
      setShareConfirmationDialog({ audience, open: true });
    },
    [setShareConfirmationDialog],
  );

  const closeBulkShareConfirmationDialog = useCallback(
    (success: boolean) => {
      const { data } = bulkShareConfirmationDialog;

      setBulkShareConfirmationDialog({ open: false });

      if (success) {
        refetch();
        handleSelectionChange(data, SelectOperation.UNSELECT);
      }
    },
    [bulkShareConfirmationDialog, handleSelectionChange, refetch, setBulkShareConfirmationDialog],
  );

  const closeShareConfirmationDialog = useCallback(() => {
    setShareConfirmationDialog({ open: false });
  }, [setShareConfirmationDialog]);

  const redirectToDealCreation = useCallback(
    (audience: AudienceRow) => {
      history.replace({
        pathname: history.location.pathname,
        search: `${history.location.search}&fallback=true`,
      });
      history.push('/deals/create', {
        deal: {
          account_uid: audience.account?.uid,
          targeting: {
            audience: {
              openaudience_custom: {
                op: ComparisonType.INTERSECTS,
                val: `openaudience-${audience.id}`,
              },
            },
          },
        },
        useAsTemplate: true,
      });
    },
    [history],
  );

  const closeDuplicateDialog = useCallback(
    (success: boolean) => {
      setAudienceToDuplicate(null);
      if (success) {
        refetch();
      }
    },
    [refetch, setAudienceToDuplicate],
  );

  const columns = isInternalOxUser
    ? tableColumns(getProviderName)
    : tableColumns(getProviderName).filter(({ title }) => title !== 'Organizations');

  const rowActions = useMemo(
    () => [
      {
        label: 'Duplicate',
        onClick: audience => setAudienceToDuplicate(audience),
        'data-test': 'audience-duplicate',
      },
      {
        label: 'Share Audience',
        onClick: audience => openShareConfirmationDialog(audience),
        'data-test': 'audience-share',
      },
      {
        label: 'Edit',
        onClick: ({ id }) => history.push(`/audiences/${id}/edit`),
        topDivider: true,
        'data-test': 'audience-edit',
        allowed: canEditAudience,
        notAllowedMessage: notEditableMessage,
      },
      {
        label: 'Archive',
        danger: true,
        onClick: audience => {
          openArchiveConfirmationDialog([audience]);
        },
        'data-test': 'audience-archive',
      },
    ],
    [criteria.pagination?.pageNumber, history, openArchiveConfirmationDialog, openShareConfirmationDialog],
  );

  useEffect(() => {
    history.replace({
      ...history.location,
      state: {
        ...history.location.state,
        page: criteria.pagination?.pageNumber,
      },
    });
  }, [criteria.pagination?.pageNumber]);

  useEffect(() => {
    const preloadOffset = (queryCrit.offset ?? 0) + (initialCriteria.pagination?.pageSize ?? 50);
    let timeoutId: NodeJS.Timeout | null = setTimeout(() => {
      if (preloadOffset < count && !loading && !error && feedLoaded) {
        fetchMore({
          variables: {
            offset: preloadOffset,
          },
        });
      }
      timeoutId = null;
    }, 500);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [count, loading, error, queryCrit.offset, feedLoaded, fetchMore]);

  if (error) throw new Error('Audiences Error');

  const isTableEmpty = !loading && !criteria.filters && audienceFeed.length === 0;
  const noSearchResults = !loading && criteria.filters && audienceFeed.length === 0;

  if (usersLoading) {
    return <Loader />;
  }

  const accountOptions = audienceAccounts.map(({ name }) => name);
  if (countLoading || isTableEmpty) {
    return (
      <AudiencesHeader
        handleCriteriaChange={handleCriteriaChange}
        statuses={uiAudienceStatuses}
        providers={providers.map(({ name }) => name)}
        users={users.map(user => ({
          displayName: `${user.first_name} ${user.last_name} (${user.email_address})`,
          isHidden: (selectedAccounts: string[]) =>
            !!selectedAccounts?.length && !selectedAccounts.some(accountName => user.account.name === accountName),
          value: user.id,
        }))}
        audienceAccounts={accountOptions}
        isInternalOxUser={isInternalOxUser}
      >
        <Paper padding="none">
          {loading || countLoading ? (
            <AudienceTable
              data={[]}
              columns={columns}
              loading={loading}
              highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
              criteria={criteria}
              stickyHeaderPosition={TOP_BAR_HEIGHT}
              fixedLayout
              selectedItems={[]}
              toggleSelect={() => void 0}
              rowActions={rowActions}
              primaryRowAction={{
                label: 'CREATE DEAL',
                onClick: redirectToDealCreation,
                allowed: audience => canCreateDealWithAudience(audience),
                notAllowedMessage: notSupportDealMesssage,
                'data-test': 'create-deal',
              }}
            ></AudienceTable>
          ) : (
            <EmptyTablePlaceholder />
          )}
        </Paper>
      </AudiencesHeader>
    );
  }

  return (
    <>
      <AudiencesHeader
        handleCriteriaChange={handleCriteriaChange}
        statuses={uiAudienceStatuses}
        providers={providers.map(({ name }) => name)}
        audienceAccounts={accountOptions}
        users={users.map(user => ({
          displayName: `${user.first_name} ${user.last_name} (${user.email_address})`,
          isHidden: (selectedAccounts: string[]) =>
            !!selectedAccounts?.length && !selectedAccounts.some(accountName => user.account.name === accountName),
          value: user.id,
        }))}
        isInternalOxUser={isInternalOxUser}
      >
        <Paper padding="none">
          <AudienceTable
            data={audienceFeed}
            columns={columns}
            criteria={criteria}
            rowActions={rowActions}
            loading={loading}
            onCriteriaChange={handleCriteriaChange}
            highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
            stickyHeaderPosition={TOP_BAR_HEIGHT}
            selectedItems={selectedAudiences}
            toggleSelect={handleSelectionChange}
            onRowClick={({ id }) => history.push(`/audiences/${id}`)}
            fixedLayout
            CustomManagementSection={({ type }) => (
              <>
                <TableBulkActions
                  openArchiveConfirmationDialog={openArchiveConfirmationDialog}
                  openBulkShareConfirmationDialog={openBulkShareConfirmationDialog}
                  audiences={selectedAudiences}
                />
                {type === 'top' && (
                  <Box ml={2}>
                    <SwitchField
                      checked={showMyAudiences}
                      data-test="show-my-audiences"
                      label="Show my audiences"
                      onChange={handleMyAudiences}
                    />
                  </Box>
                )}
              </>
            )}
            primaryRowAction={{
              label: 'CREATE DEAL',
              onClick: redirectToDealCreation,
              allowed: audience => canCreateDealWithAudience(audience),
              notAllowedMessage: notSupportDealMesssage,
              'data-test': 'create-deal',
            }}
          >
            {noSearchResults && <SearchResultPlaceholder columnsAmount={tableColumns(getProviderName).length + 2} />}
          </AudienceTable>
        </Paper>
      </AudiencesHeader>

      {archiveConfirmationDialog.open && (
        <ArchiveConfirmationDialog
          onClose={closeArchiveConfirmationDialog}
          audiences={archiveConfirmationDialog.data}
          open={archiveConfirmationDialog.open}
        />
      )}

      {bulkShareConfirmationDialog.open && (
        <BulkShareConfirmationDialog
          onClose={closeBulkShareConfirmationDialog}
          audiences={(bulkShareConfirmationDialog.data || []).map(({ id, name }) => ({ id, name }))}
          open={bulkShareConfirmationDialog.open}
        />
      )}

      {shareConfirmationDialog.open && (
        <ShareConfirmationDialog
          onClose={closeShareConfirmationDialog}
          audience={{
            id: shareConfirmationDialog.audience?.id,
            name: shareConfirmationDialog.audience?.name || '',
          }}
          open={shareConfirmationDialog.open}
          fromList
        />
      )}

      {audienceToDuplicate && <AudienceDuplicate onClose={closeDuplicateDialog} audience={audienceToDuplicate} />}
    </>
  );
}

export const AudiencesList = withAudience(AudiencesListContent);
