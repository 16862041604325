import { AudienceStatus } from 'modules/audiences/constants';
import { SegmentStatus } from 'modules/segments/constants';
import moment from 'moment';

export function isSoonToBeExpired(estimatedExpiryDate, status) {
  if (status === AudienceStatus.EXPIRED || status === SegmentStatus.EXPIRED) {
    return false;
  } else if (!estimatedExpiryDate) {
    return false;
  }

  const daysRemaining = moment.duration(new Date(estimatedExpiryDate).getTime() - new Date().getTime()).asDays();

  return daysRemaining <= 10;
}

export function daysToExpiration(estimatedExpiryDate) {
  if (!estimatedExpiryDate) {
    return null;
  }

  const daysRemaining = Math.round(
    moment.duration(new Date(estimatedExpiryDate).getTime() - new Date().getTime()).asDays(),
  );

  return daysRemaining < 0 ? 0 : daysRemaining;
}

export function isExpired(status) {
  return status === AudienceStatus.EXPIRED || status === SegmentStatus.EXPIRED;
}

export function segmentDaysRemaining(segments) {
  return segments
    .filter(segment => isSoonToBeExpired(segment.expiryDate, segment.segmentStatus))
    .map(({ expiryDate }) => daysToExpiration(expiryDate))
    .sort((a, b) => a - b);
}

export const audienceExpiredText = 'Audience hasn’t been refreshed in more than 90 days and is expired.';

export const audienceSoonWillBeExpiredText = 'Audience is pending expiration due to 90 day expiration window.';

export const segmentExpiredText = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has expired segment.'
    : 'Segment data is expired and cannot be used for audience creation';

export const segmentSoonWillBeExpired = (fromDeal, segmentDaysRemaining) =>
  fromDeal
    ? `Audience associated with a given deal has segments that will expire in next ${segmentDaysRemaining} ${
        segmentDaysRemaining === 1 ? 'day' : 'days'
      }.`
    : `Segment is going to expire within the next ${segmentDaysRemaining} ${
        segmentDaysRemaining === 1 ? 'day' : 'days'
      }`;
