import React from 'react';
import { AlertTooltip } from 'components/AlertTooltip';
import { makeStyles } from '@material-ui/styles';
import { Error, Warning } from '@material-ui/icons';

import {
  daysToExpiration,
  isExpired,
  isSoonToBeExpired,
  segmentExpiredText,
  segmentSoonWillBeExpired,
} from 'utils/expiryDates';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '5px',
  },
});

export const SegmentCell = ({ name, estimatedExpiryDate, status }) => {
  const { flex, marginRight } = useStyles();

  const showExpiredAlert = isExpired(status);
  const showExpireSoonAlert = isSoonToBeExpired(estimatedExpiryDate, status);
  const daysRemaining = daysToExpiration(estimatedExpiryDate);

  const expiredText = segmentExpiredText(false);
  const soonWillBeExpiredText = segmentSoonWillBeExpired(false, daysRemaining);

  return (
    <div className={flex}>
      <p className={marginRight}>{name}</p>
      {showExpiredAlert && (
        <AlertTooltip color="#D83158" title={expiredText} icon={<Error fontSize="small" color="inherit" />} />
      )}
      {showExpireSoonAlert && (
        <AlertTooltip
          color="#E8B046"
          title={soonWillBeExpiredText}
          icon={<Warning fontSize="small" color="inherit" />}
        />
      )}
    </div>
  );
};
