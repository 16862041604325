import React, { useCallback, useState, memo } from 'react';
import { useMutation } from '@apollo/client';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import { ConfirmationDialog } from '@openx/components/core';
import { Table } from '@openx/components/table';
import UpdateSegment from 'graphql/mutation/segments/UpdateSegment.gql';
import { Segment, UpdateSegmentMutation, UpdateSegmentMutationVariables } from 'types/schemaTypes';
import { useSession } from 'modules/auth/AuthProvider';
import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';

import { confirmationDialogTableColumns } from './tableColumns';

const useStyles = makeStyles<Theme>(theme => ({
  contentWrapper: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

type ConfirmationDialogSegmentRow = Pick<Segment, 'id' | 'name' | 'reach' | 'created_date'>;

interface Props {
  data?: ConfirmationDialogSegmentRow[];
  onClose: (success: boolean) => void;
  open: boolean;
}

function ArchiveConfirmationDialogRaw({ data = [], onClose, open }: Props): JSX.Element {
  const { contentWrapper } = useStyles();

  const { id: userId } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [pushLog] = useMutation(createLog);
  const [loading, setLoading] = useState(false);
  const [updateSegment] = useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegment);
  const onConfirmed = useCallback(async () => {
    setLoading(true);
    try {
      const { errors } = await updateSegment({
        variables: {
          where: { id: { _in: data.map(({ id }) => id) } },
          set: { is_archived: true },
        },
      });
      if (errors) throw new Error(errors.join(', '));
      enqueueSnackbar(`Segment${data.length > 1 ? 's' : ''} successfully archived`, { variant: 'success' });
      onClose(true);
    } catch (e) {
      enqueueSnackbar(`Failed to archive segment${data.length > 1 ? 's' : ''}`, { variant: 'error' });

      const location = window.location.href;
      const user = await getCurrentUser();
      await pushLog({
        variables: {
          type: 'error',
          location: location,
          msg: `Failed to archive segment${data.length > 1 ? 's' : ''}`,
          name: user?.displayName,
          email: user?.email,
        },
      });
    }
    setLoading(false);
  }, [data, enqueueSnackbar, onClose, pushLog, updateSegment]);

  const onCancel = useCallback(() => {
    if (loading) return;
    onClose(false);
  }, [loading, onClose]);

  return (
    <ConfirmationDialog
      isOpen={open}
      title="Archive Segments"
      confirmLabel="archive"
      onCancel={onCancel}
      onConfirmed={onConfirmed}
      customContent={true}
      isLoading={loading}
      confirmationButtonColor="danger"
      data-test="confirmation-dialog"
    >
      <div className={contentWrapper} data-test="confirmation-dialog-table">
        <Table data={data} columns={confirmationDialogTableColumns} loading={false} fixedLayout />
      </div>
    </ConfirmationDialog>
  );
}

export const ArchiveConfirmationDialog = memo(ArchiveConfirmationDialogRaw, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
