import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { Error, NotFoundImage } from '@openx/components/core';

import { TOP_BAR_HEIGHT } from 'config';

const useStyles = makeStyles({
  error: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  },
});

export const PageNotFound = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const fallback = searchParams.get('fallback');

  const redirectHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const redirectFallback = () => {
    searchParams.delete('fallback');
    const target = `${history.location.pathname}${searchParams.size ? `?${searchParams.toString()}` : ''}`;
    window.history.replaceState({ key: 'default', usr: window.history.state?.usr }, '', target);
    window.location.replace(target);
  };

  if (fallback) {
    redirectFallback();
    return <span>Redirecting...</span>;
  }

  return (
    <Error
      title="Page Not Found"
      titlePrefix="OpenAudience"
      subtitle="We've looked everywhere and we just can't find that page in our universe."
      actions={[
        {
          color: 'secondary',
          variant: 'contained',
          onClick: redirectHome,
          'data-test': 'button-back',
          children: 'Take me home please',
        },
      ]}
      PictureComponent={NotFoundImage}
      className={classes.error}
      data-test="page-not-found"
    />
  );
};
