import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useQuery } from '@apollo/client';

import { PageHeader } from '@openx/components/header';
import { Loader } from '@openx/components/core';
import { ActionBar } from 'components/ActionBar';
import { ShareConfirmationDialog } from 'modules/audiences/components/AudienceShare/ShareConfirmationDialog';
import { ContentWrapper } from 'components/ContentWrapper';
import {
  useAudience,
  withAudience,
  ActionType,
  getExportType,
  getDirectProvider,
  getIsStateSynced,
  fields,
  getName,
} from 'modules/audiences/AudienceProvider';
import GetAudience from 'graphql/query/audiences/GetAudience.gql';
import GetAssociatedDeals from 'graphql/query/audiences/GetAssociatedDeals.gql';
import { GetAudienceQuery, GetAudienceQueryVariables, AudienceShare } from 'types/schemaTypes';
import { ReportStatus } from 'modules/audiences/AudienceProvider/types';
import { RouteAudienceDetailsParams } from 'routes';
import { DetailsReadOnly, Insights, AudienceCriteria } from 'modules/audiences/components';
import { useProviders, useDemographicsOptions, defaultAgeRange } from 'modules/audiences/hooks';

import { mapAudienceToProvider, canEditAudience, isInsightRequestSupported, notEditableMessage } from '../utils';
import { AllowedOrganizations } from '../components/AllowedOrganizations';
import { AssociatedDeals } from '../components/AssociatedDeals';
import { AudienceAlerts } from '../components/AudienceAlerts';

import { ButtonActionDefinition } from '../../../components/ActionBar/types';
import { useSideDrawer } from 'context';

const { AGE_FIELD, GENDER_FIELD, NORMALIZED_DEFINITION_FIELD } = fields;

const AudienceEditContent = (): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();
  const { audienceId } = useParams<RouteAudienceDetailsParams>();
  const { state, dispatch } = useAudience();

  const goBack = useCallback(() => history.goBack(), [history]);

  const exportType = getExportType(state);
  const directProvider = getDirectProvider(state);
  const isStateSynced = getIsStateSynced(state);
  const name = useMemo(() => getName(state), [state]);

  const { providers } = useProviders();
  const { rangeToSegments } = useDemographicsOptions();

  const [isShareConfirmationDialogOpen, setShareConfirmationDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setShareConfirmationDialogOpen(true);
  }, [setShareConfirmationDialogOpen]);

  const handleCloseDialog = useCallback(() => {
    setShareConfirmationDialogOpen(false);
  }, [setShareConfirmationDialogOpen]);

  const {
    error: audienceError,
    data: { audience_by_pk: audienceData = undefined } = {},
    loading: audienceLoading,
    startPolling,
    stopPolling,
  } = useQuery<GetAudienceQuery, GetAudienceQueryVariables>(GetAudience, {
    variables: { id: audienceId },
    fetchPolicy: 'network-only',
    skip: !audienceId,
  });

  const {
    report_status: reportStatus,
    status,
    estimated_expiry_date: estimatedExpiryDate,
    is_archived: isArchived = false,
    audience_segments: audienceSegments = [],
    audience_share: audienceShares = [],
    error_code: errorCode,
  } = audienceData || {};

  const segments = audienceSegments.map(({ segment: { status, expiration_date } }) => ({
    segmentStatus: status,
    expiryDate: expiration_date,
  }));

  const {
    error: associatedDealsError,
    data: { deal: associatedDeals = [] } = {},
    loading: associatedDealsLoading,
  } = useQuery(GetAssociatedDeals, {
    variables: { id: audienceId },
    fetchPolicy: 'network-only',
    skip: !audienceId,
  });

  const displayInsightsPanel = isInsightRequestSupported(
    exportType,
    reportStatus as ReportStatus,
    directProvider || undefined,
  );

  const actions = useMemo(
    () => [
      {
        type: 'button',
        label: 'Share Audience',
        props: {
          color: 'default',
          disabled: isArchived,
          onClick: handleOpenDialog,
        },
      },
      {
        type: 'button',
        label: 'Edit',
        props: {
          color: 'default',
          disabled: isArchived,
          onClick: () => {
            history.push(`/audiences/${audienceId}/edit`, { ref: history.location.pathname });
          },
          allowed: !!audienceData && canEditAudience(audienceData),
          notAllowedMessage: notEditableMessage,
        },
      },
    ],
    [audienceData, notEditableMessage, audienceId, handleOpenDialog],
  );

  useEffect(() => {
    if (audienceData) {
      dispatch({
        type: ActionType.SET_AUDIENCE,
        payload: mapAudienceToProvider(audienceData, providers),
      });
    }
  }, [audienceData, dispatch, providers, rangeToSegments]);

  useEffect(() => {
    if (reportStatus === ReportStatus.SF_PROCESSING) {
      startPolling(60000);
    } else {
      stopPolling();
    }
  }, [reportStatus, startPolling, stopPolling]);

  if (audienceError) {
    throw new Error('Cannot fetch audience');
  }

  if (audienceLoading || !isStateSynced) {
    return <Loader />;
  }

  const disabledText =
    reportStatus === ReportStatus.PROCESSING
      ? 'We’re unable to provide insights as the audience is still being processed.'
      : undefined;

  return (
    <>
      <ActionBar actions={actions as ButtonActionDefinition[]} onGoBack={goBack} />
      <PageHeader
        title={`Details: ${audienceData?.name}`}
        titlePrefix="OpenAudience"
        alert={
          <AudienceAlerts
            estimatedExpiryDate={estimatedExpiryDate}
            isArchived={isArchived}
            status={status}
            segments={segments}
          />
        }
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DetailsReadOnly errorCode={errorCode} />
                </Grid>
                <Grid item xs={12}>
                  <Insights
                    audienceId={audienceId}
                    disabled={!displayInsightsPanel}
                    disabledText={disabledText}
                    disableReportGeneration={isArchived}
                    reportStatus={reportStatus}
                    audienceStatus={audienceData?.status}
                  />
                </Grid>
                {(associatedDealsLoading || associatedDeals.length > 0) && (
                  <Grid item xs={12}>
                    <AssociatedDeals
                      data={associatedDeals}
                      error={associatedDealsError}
                      loading={associatedDealsLoading}
                    />
                  </Grid>
                )}
                {audienceShares.length > 0 && (
                  <Grid item xs={12}>
                    <AllowedOrganizations
                      readonly
                      audience={{ id: audienceId, name }}
                      audienceShares={audienceShares as AudienceShare[]}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <AudienceCriteria disableDownload={isArchived} boxesVisible readonly />
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>
      {isShareConfirmationDialogOpen && (
        <ShareConfirmationDialog
          onClose={handleCloseDialog}
          audience={{ id: audienceId, name }}
          open={isShareConfirmationDialogOpen}
        />
      )}
    </>
  );
};

export const AudienceDetails = withAudience(AudienceEditContent);
