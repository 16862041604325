import React, { useEffect, useState } from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const makeBarBackgroundMap = (theme, variant) => ({
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
  gradient: `linear-gradient(${{ horizontal: '90deg', vertical: '0deg' }[variant]},${
    theme.palette.primary.main
  } 16.27%,${theme.palette.secondary.main} 74.56%)`,
});

const useStyles = makeStyles<Theme, BarStyleProps>(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: ({ variant }) => ({ horizontal: 'row', vertical: 'column' }[variant] as 'row' | 'column'),
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  valueContainer: {
    color: theme.palette.text.secondary,
    marginBottom: ({ variant }) => ({ horizontal: 0, vertical: theme.spacing(0.5) }[variant]),
    marginRight: ({ variant }) => ({ horizontal: theme.spacing(2), vertical: 0 }[variant]),
    width: ({ variant }) => ({ horizontal: 26, vertical: 'auto' }[variant]),
  },
  body: {
    background: ({ color, variant }) => makeBarBackgroundMap(theme, variant)[color],
    borderRadius: 2,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  cover: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    position: 'absolute',
    transform: 'none',
    transition: 'transform 0.4s ease',
    width: '100%',
  },
  tipContainer: {
    height: '100%',
    position: 'relative',
    width: '100%',
  },
  tip: {
    height: 3,
    position: 'absolute',
    width: 3,
  },
  tipStart: {
    background: `radial-gradient(circle 2px at 0% 100%, transparent 100%, ${theme.palette.background.default} 100%)`,
    left: ({ variant }) => ({ horizontal: -2, vertical: -1 }[variant]),
    top: ({ variant }) => ({ horizontal: -1, vertical: 'calc(100% - 1px)' }[variant]),
    transform: ({ variant }) => ({ horizontal: 'none', vertical: 'rotate(-90deg)' }[variant]),
  },
  tipEnd: {
    background: `radial-gradient(circle 2px at 0% 0%, transparent 100%, ${theme.palette.background.default} 100%)`,
    left: ({ variant }) => ({ horizontal: -2, vertical: 'calc(100% - 2px)' }[variant]),
    top: ({ variant }) => ({ horizontal: 'calc(100% - 2px)', vertical: 'calc(100% - 1px)' }[variant]),
    transform: ({ variant }) => ({ horizontal: 'none', vertical: 'rotate(-90deg)' }[variant]),
  },
}));

type Color = 'primary' | 'secondary' | 'gradient';
type Variant = 'horizontal' | 'vertical';

interface BarProps {
  color?: Color;
  value?: number;
  valueVisible?: boolean;
  variant?: Variant;
}

interface BarStyleProps {
  color: Color;
  variant: Variant;
}

export function Bar({
  color = 'primary',
  value = 100,
  valueVisible = false,
  variant = 'horizontal',
}: BarProps): JSX.Element {
  const valueParsed = Math.max(0, Math.min(value, 100));

  const [currentValue, setCurrentValue] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setCurrentValue(valueParsed);
    }, 200);
  }, [valueParsed]);

  const classes = useStyles({ color, variant });
  const transformAxis = { horizontal: 'X', vertical: 'Y' }[variant];
  const transformValue = { horizontal: 1, vertical: -1 }[variant] * currentValue;

  return (
    <div className={classes.root}>
      {valueVisible && (
        <div className={classes.valueContainer}>
          <Typography variant="body2">{valueParsed}%</Typography>
        </div>
      )}
      <div className={classes.body}>
        <div className={`${classes.cover}`} style={{ transform: `translate${transformAxis}(${transformValue}%)` }}>
          <div className={classes.tipContainer}>
            <div className={`${classes.tip} ${classes.tipStart}`} />
            <div className={`${classes.tip} ${classes.tipEnd}`} />
          </div>
        </div>
      </div>
    </div>
  );
}
